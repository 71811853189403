import React, { useState } from "react"


class FrequentlyAskedQuestion{
	constructor(question, answer){
		this.question = question;
		this.answer = answer;
	}
}

const QUESTIONS = [
	new FrequentlyAskedQuestion('How do I pay for TimeKeeper?', 'You can pay using any major debit or credit card once you are happy to proceed after your free trial.'),
	new FrequentlyAskedQuestion('Will I be charged automatically when the trial ends?', 'No - we do not collect any card details until you are happy to proceed after the free trial.'),
	new FrequentlyAskedQuestion('Are my card details safe on this site?', 'Absolutely! All billing information is stored on our payment processing partner Stripe which has the most stringent level of certification available in the payments industry.'),
	new FrequentlyAskedQuestion('Can I try your service for free?', 'Of course! We have a free 14 day trial with no credit card to anyone who wants to try TimeKeeper.'),
	new FrequentlyAskedQuestion('How long are your contracts?', 'Currently, we offer monthly and annual subscriptions. You can upgrade or cancel your account at any time with no further obligation.'),
	new FrequentlyAskedQuestion('Does TimeKeeper work on tablet, mobile and web? Android and iOS?', 'Yes, TimeKeeper works for recent versions of Android and iOS, either on mobile or tablet. Also you can enable employees to clock in via their web browser.'),
	new FrequentlyAskedQuestion('Can my employees use their phone to clock in?', 'Yes, whenever an employee is added to TimeKeeper they will receive an invite to the system. If you have enabled that employee to have access to the mobile app, then they will be able to clock in from mobile.'),
	new FrequentlyAskedQuestion('Can I use one tablet for all my employees?', 'Yes, if you log in with administrator credentials a keypad will be shown. Each employee can enter their 4 digit pin and then clock in from this tablet.'),
	new FrequentlyAskedQuestion('How many devices can I use with TimeKeeper?', 'TimeKeeper will allow you to use as many tablets as you want.'),
	new FrequentlyAskedQuestion('Does TimeKeeper record my location on clock in?', 'If enabled, TimeKeeper requires location for clocking into and out of work. For mobile clock in, it is on by default but can be turned off by an administrator.'),
	new FrequentlyAskedQuestion('How many employees can I use TimeKeeper with?', 'Our current maximum number of employees for each company is 10,000.'),
	new FrequentlyAskedQuestion('Can I use TimeKeeper across many office branches?', 'Yes, we can work with any number of office branches.'),
	new FrequentlyAskedQuestion('Is there a limit to the number of jobs I can use TimeKeeper with?', 'No, you can have as many jobs as you like.'),
	new FrequentlyAskedQuestion('Do you have any training materials?', 'Yes we have a full helpdesk website with videos at help.timekeeper.co.uk.'),
	new FrequentlyAskedQuestion('Are there any penalties for cancelling a subscription?', 'No, your subscription would just be cancelled at the end of that current billing period e.g. the end of month and no extra charges will be required.'),
	new FrequentlyAskedQuestion('Can I cancel my subscription?', 'You can cancel your subscription anytime in your account. Once the subscription is cancelled, you will not be charged next month. You will continue to have access to your account until your current subscription expires.'),
	new FrequentlyAskedQuestion('Do you support visitor sign in and out?', 'Yes we have a paid visitor add on at £29 per month.'),
	new FrequentlyAskedQuestion('I have more questions...', 'Sure thing - drop us an email at info@timekeeper.co.uk')
]

const FAQ = () => (
	<div className="bg-gray-50">
	  <div className="max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
	    <div className="max-w-3xl mx-auto">
	      <h2 className="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
	        Frequently Asked Questions
	      </h2>
	      <p className="mt-4 text-base text-center leading-7 text-gray-500">
	          Got a question? We've got answers.
	        </p>

	          {QUESTIONS.map((entry, idx) =>
	          	 <FAQEntry entry={entry} idx={idx} />
	          )}

	    </div>
	  </div>
	</div>
)

const FAQEntry = ({entry, idx}) => {
		const [showDetail, setShowDetail] = useState(false);
		return (
			<div key={idx} className="mt-6 border-t-2 border-gray-200 pt-6" onClick={() => setShowDetail(!showDetail)}>
		    	<dl>
		      		<div>
			            <dt className="text-lg leading-7">
			              <button className="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900">
			                <span className="font-medium text-gray-900">
			                  {entry.question}
			                </span>
			                <span>
			                	{!showDetail &&
			                		<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
									  <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
									</svg>
								}
								{showDetail &&
			                		<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
									  <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
									</svg>
								}
			                </span>
			              </button>
			            </dt>
			            {showDetail &&
			            	<dd className="mt-2 pr-12">
				              <p className="text-base leading-6 text-gray-500">
				                {entry.answer}
				              </p>
				            </dd>
				        }
		          </div>
		    	</dl>
			</div>
		)
}

export default FAQ;
